/* Provide sufficient contrast against white background */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

a {
  color: #0366d6;
}

body {
  margin: 0;
  height: 100vh;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.container {
  max-width: 80vw !important;
}

.date-picker {
  padding: 14px 18.5px !important;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  width: 100%;
}

.date-label {
  position: absolute;
  top: -6px;
  left: 10px;
  z-index: 1;
  color: rgba(0, 0, 0, 0.54);
  padding: 0 5px;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  background-color: #fff;
}

.rdt_TableHead {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
}

.rdt_TableCol {
  background-color: #fff;
  opacity: 1;
}

.sticky-header > div {
  overflow: visible;
}

.sticky-footer {
  position: sticky;
  bottom: 15px;
  width: 100%;
  z-index: 101;
}

input {
  -webkit-appearance: none;
  box-shadow: none !important;
  border: none;
}
:-webkit-autofill {
  color: #fff !important;
}

.react-select > div {
  padding: 8px;
  z-index: 2;
}